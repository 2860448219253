import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <p>enjoy this comic while you're here :) </p>
    <img src='https://i.imgur.com/nnhQbpY.jpg'></img>
  </Layout>
);

export default NotFoundPage;
